<template>
  <div class="elements">
    <input class="primary" type="text" placeholder="text" />
    <input class="secondary" type="text" placeholder="text" />
    <input class="box secondary" type="text" placeholder="text" />
    <div class="dark uk-margin-medium-top" style="background-color: #1E1E1E;padding: 20px 0;">
      <input class="dark" type="text" placeholder="text" />
      <input class="box dark" type="text" placeholder="text" />
    </div>
    <div class="uk-section">
      <div class="input-icon primary">
        <span uk-icon="icon: user"></span>
        <input type="text" />
      </div>
      <div class="input-icon secondary">
        <span uk-icon="icon: user"></span>
        <input type="text" />
      </div>
      <div class="input-icon dark">
        <span uk-icon="icon: user"></span>
        <input type="text" />
      </div>
      <div class="input-icon secondary line">
        <span uk-icon="icon: user"></span>
        <input type="text" />
      </div>
    </div>
    <div class="uk-section">
      <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
        <label><input class="uk-checkbox" type="checkbox" />B</label>
        <label><input class="uk-radio" type="radio" />B</label>
      </div>
    </div>
    <div class="uk-section">
      <textarea class="uk-textarea primary"></textarea>
    </div>
    <div class="uk-section">
      <textarea class="uk-textarea secondary"></textarea>
    </div>
    <div class="uk-section">
      <textarea class="uk-textarea dark"></textarea>
    </div>
    <div class="uk-section">
      <p>
        <button class="primary">Click Me</button>
      </p>
      <p>
        <button class="primary-full">Click Me</button>
      </p>
      <p>
        <button class="secondary">Click Me</button>
      </p>
      <p>
        <button class="secondary-full">Click Me</button>
      </p>
      <p>
        <button class="dark">Click Me</button>
      </p>
      <p>
        <button class="dark-full">Click Me</button>
      </p>
    </div>
    <div class="uk-section">
      <h1 class="primary">I'M A H1 TITLE. Je suis un titre H1.</h1>
      <h1 class="secondary">I'M A H1 TITLE. Je suis un titre H1.</h1>
      <h1 class="orange">I'M A H1 TITLE. Je suis un titre H1.</h1>
      <h1 class="dark">I'M A H1 TITLE. Je suis un titre H1.</h1>

      <h2 class="primary">I'M A H2 TITLE. Je suis un titre H2.</h2>
      <h2 class="secondary">I'M A H2 TITLE. Je suis un titre H2.</h2>
      <h2 class="orange">I'M A H2 TITLE. Je suis un titre H2.</h2>
      <h2 class="dark">I'M A H2 TITLE. Je suis un titre H2.</h2>

      <h3 class="primary">I'M A H3 TITLE. Je suis un titre H3.</h3>
      <h3 class="secondary">I'M A H3 TITLE. Je suis un titre H3.</h3>
      <h3 class="orang">I'M A H3 TITLE. Je suis un titre H3.</h3>
      <h3 class="dark">I'M A H3 TITLE. Je suis un titre H3.</h3>

      <h4 class="primary">I'M A H4 TITLE. Je suis un titre H4.</h4>
      <h4 class="secondary">I'M A H4 TITLE. Je suis un titre H4.</h4>
      <h4 class="orange">I'M A H4 TITLE. Je suis un titre H4.</h4>
      <h4 class="dark">I'M A H4 TITLE. Je suis un titre H4.</h4>

      <p class="primary">Lorem ipsum dolor sit.</p>
      <p class="secondary">Lorem ipsum dolor sit.</p>
      <p class="orange">Lorem ipsum dolor sit.</p>
      <p class="dark">Lorem ipsum dolor sit.</p>

      <span class="primary">Lorem ipsum dolor sit.</span>
      <span class="secondary">Lorem ipsum dolor sit.</span>
      <span class="orange">Lorem ipsum dolor sit.</span>
      <span class="dark">Lorem ipsum dolor sit.</span>

      <label class="primary">Lorem ipsum dolor sit.</label>
      <label class="secondary">Lorem ipsum dolor sit.</label>
      <label class="orange">Lorem ipsum dolor sit.</label>
      <label class="dark">Lorem ipsum dolor sit.</label>
    </div>
    <div class="uk-section">
      <p>
        <span class="primary" uk-icon="icon: user"></span>
      </p>
      <p>
        <span class="secondary" uk-icon="icon: user"></span>
      </p>
      <p>
        <span class="orange" uk-icon="icon: user"></span>
      </p>
      <p>
        <span class="success" uk-icon="icon: user"></span>
      </p>
      <p>
        <span class="warning" uk-icon="icon: user"></span>
      </p>
      <p>
        <span class="dark" uk-icon="icon: user"></span>
      </p>
    </div>
    <div class="uk-section">
      <div class="banner primary" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptate, perspiciatis!</p>
      </div>
      <div class="banner secondary" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptate, perspiciatis!</p>
      </div>
      <div class="banner orange" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptate, perspiciatis!</p>
      </div>
      <div class="banner warning" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptate, perspiciatis!</p>
      </div>
      <div class="banner success" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptate, perspiciatis!</p>
      </div>
      <div class="banner danger" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptate, perspiciatis!</p>
      </div>
    </div>
    <div class="uk-section">
      <div class="uk-margin image-uploader primary" uk-margin>
        <div uk-form-custom="target: true">
          <input type="file" />
          <input class="uk-input uk-form-width-medium" type="text" placeholder="Select file" disabled />
        </div>
        <button class="uk-button uk-button-default">Submit</button>
      </div>
      <div class="uk-margin image-uploader secondary" uk-margin>
        <div uk-form-custom="target: true">
          <input type="file" />
          <input class="uk-input uk-form-width-medium" type="text" placeholder="Select file" disabled />
        </div>
        <button class="uk-button uk-button-default">Submit</button>
      </div>
    </div>
    <div class="uk-section">
      <div class="uk-margin select-icon primary">
        <div uk-form-custom="target: > * > span:last-child">
          <select>
            <option disabled>Primary With Icon</option>
            <option value="1">Option 01</option>
            <option value="2">Option 02</option>
            <option value="3">Option 03</option>
          </select>
          <span class="uk-link">
            <span uk-icon="icon: pencil"></span>
            <span></span>
          </span>
        </div>
      </div>
      <div class="uk-margin select-default primary">
        <div uk-form-custom="target: > * > span:first-child">
          <select>
            <option disabled>Primary Default</option>
            <option value="1">Option 01</option>
            <option value="2">Option 02</option>
            <option value="3">Option 03</option>
            <option value="4">Option 04</option>
          </select>
          <button class="uk-button uk-button-default" type="button" tabindex="-1">
            <span></span>
            <span uk-icon="icon: chevron-down"></span>
          </button>
        </div>
      </div>
      <div class="uk-margin select-default primary-full">
        <div uk-form-custom="target: > * > span:first-child">
          <select>
            <option disabled>Primary Default</option>
            <option value="1">Option 01</option>
            <option value="2">Option 02</option>
            <option value="3">Option 03</option>
            <option value="4">Option 04</option>
          </select>
          <button class="uk-button uk-button-default" type="button" tabindex="-1">
            <span></span>
            <span uk-icon="icon: chevron-down"></span>
          </button>
        </div>
      </div>
      <div class="uk-margin select-default secondary">
        <div uk-form-custom="target: > * > span:first-child">
          <select>
            <option disabled>Primary Default</option>
            <option value="1">Option 01</option>
            <option value="2">Option 02</option>
            <option value="3">Option 03</option>
            <option value="4">Option 04</option>
          </select>
          <button class="uk-button uk-button-default" type="button" tabindex="-1">
            <span></span>
            <span uk-icon="icon: chevron-down"></span>
          </button>
        </div>
      </div>
      <div class="uk-margin select-default secondary-full">
        <div uk-form-custom="target: > * > span:first-child">
          <select>
            <option disabled>Primary Default</option>
            <option value="1">Option 01</option>
            <option value="2">Option 02</option>
            <option value="3">Option 03</option>
            <option value="4">Option 04</option>
          </select>
          <button class="uk-button uk-button-default" type="button" tabindex="-1">
            <span></span>
            <span uk-icon="icon: chevron-down"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'elements'
}
</script>
